// @import "../Pages_one/Admin.scss";

.output_mainCont{
    width: 100%;
    height: 100vh;
    // border : 2px solid;
    // display: flex;
    // flex-direction: column;
    // align-items: center;
    // justify-content: center;
    .data {
      display: flex;
      flex-wrap: wrap;
      gap: 30px;
      margin-top: 10px;
    
      div {
        width: 240px;
        display: flex;
        flex-direction: column;
        // border: 1px solid;
        gap: 5px;
        .input_label {
          font-weight: 600;
          font-size: 14.9936px;
          line-height: 25px;
          /* identical to box height, or 170% */

          text-transform: capitalize;

          color: #000000;
        }
        .select{
            height: 38px;
            background-color: #BAC5EB;
            border-radius: 5px;
            outline: none;
        }
        .Input {
          height: 38px;
          padding: 10px;
          border: 0.37484px solid #3755be;
          border-radius: 4.49808px;
          outline: none;
          font-weight: 400;
          font-size: 13.4943px;
          line-height: 18px;
        }
      }
    }
}
.options{
    font-weight: 400;
font-size: 18px;
line-height: 24px;
background-color: white;
}
.ant-table-thead>tr>th {
    background-color: #E8ECF8;
    height: 45px;

}
.ant-table-tbody > tr > td {
    font-weight: 600;
font-size: 13.4625px;
line-height: 19px;
/* identical to box height, or 144% */

text-transform: capitalize;

color: #000000;

}