.slidebar_cont{
    height: 100vh;
    width: 260px;
    z-index: 999;
   
    background-color: #fff;

    position: fixed;
    box-shadow: 0 0 15px 0 #22292f0d;
    display: flex;
    flex-direction: column;
    align-items: center;
    .logo{
        margin-top: 45px;
    }
    .btnsCont{
        margin-top: 76px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        .btnselect{
           
            width: 224px;
            height: 40px;
            display: flex;
            font-weight: 500;
        
            
            padding: 0 28px;
            align-items: center;
            border-radius: 4.5px;
        }
        
    }

}
.sidebar_togal_unselect{
    color: #8C8C8C;
    background-color: white
}
.sidebar_togal_select{
    background-color: #3755BE;
    color : white;
}