.navbar {
  width: 100%;
 
  height: 46px;
  display: flex;
  justify-content: flex-end;
  padding: 13px 18px;
  align-items: center;
  border-radius: 4px;
  gap: 16px;
  box-shadow: -2.25px -2.25px 18.75px rgba(0, 0, 0, 0.05),
    3px 3px 18.75px rgba(0, 0, 0, 0.05);
  button {
    background-color: white;
    border: none;
    padding-top: 8px;
  }
  span {
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    color: #3755be;
  }
}