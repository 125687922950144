.header {
  width: 100%;
  height: 45px;
  display: flex;
  align-items: center;
}
.main_Cont {
  width: 100%;

  height: auto;


  .section_one {
    width: 100%;
    padding-bottom: 20px;
    padding-right: 74px;

    .heading {
      font-size: 19.5px;
      line-height: 26px;

      text-transform: capitalize;
    }
    .filesUploadrow {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      gap: 10px;
      .uploadbtn {
        border: none;
        background-color: #4caf50;
        height: 38px;
        border-radius: 4.5px;
        padding: 6px 15px;
        color: white;
        display: flex;
        align-items: center;
        gap: 5px;
      }
    }
    .data {
      display: flex;
      flex-wrap: wrap;
      gap: 30px;
      margin-top: 20px;
      div {
        width: 240px;
        display: flex;
        flex-direction: column;
        // border: 1px solid;
        gap: 5px;
        .input_label {
          font-weight: 600;
          font-size: 14.9936px;
          line-height: 25px;
          /* identical to box height, or 170% */

          text-transform: capitalize;

          color: #000000;
        }
        Input {
          height: 38px;
          padding: 10px;
          border: 0.37484px solid #3755be;
          border-radius: 4.49808px;
          outline: none;
          font-weight: 400;
          font-size: 13.4943px;
          line-height: 18px;
        }
      }
    }
  }
  .section_two {
    width: 100%;
    padding-right: 10px;
    height: 30%;

    padding-bottom: 55px;
    .table_data {
      .add_data {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 10px;
      }
    }
  }
}
.ant-table table {
  font-size: 11px;
}
.AddProduct_slidebar {
  width: 600px;
  height: 720px;
  //   border: 2px solid;
  box-shadow: -2.25px -2.25px 18.75px rgba(0, 0, 0, 0.05);

  position: absolute;
  right: 0px;
  background-color: white;
  padding: 40px 45px;
  top: 63px;

  clip-path: inset(0% 0% 0% 100%);
  transition-property: clip-path;

  transition-duration: 1s;

  // display: none;

  .row {
    width: 100%;
    display: flex;
    gap: 38px;
    margin-bottom: 40px;

    .inputBox {
      display: flex;
      flex-direction: column;
      gap: 4px;
      width: 100%;
      .input_label {
        font-weight: 600;
        font-size: 14.9936px;
        line-height: 25px;
        margin-bottom: 5px;

        text-transform: capitalize;

        color: #000000;
      }
      Input {
        height: 38px;
        padding: 10px;
        border: 0.37484px solid #3755be;
        border-radius: 4.49808px;
        outline: none;
        font-weight: 400;
        font-size: 13.4943px;
        line-height: 18px;
      }
    }
  }

  .AddProduct_btn {
    padding: 5px 36px;
    background-color: white;
    border-radius: 4px;
    font-weight: 600;
    font-size: 14.8148px;
    line-height: 25px;
    border: none;
  }
}
.bar{
   clip-path: inset(0% 0% 0% 0%);
}