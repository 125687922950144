* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  // font-size: 20px;
  font-weight: 400;
}
.wrapper {
  
  max-width: 100vw;
  height: 100vh;
  display: flex;
  .leftcont {
    width: 50%;
    max-width: 1053px;
    overflow: hidden;
    height: 100%;
    // border: 2px solid;
    padding: 112px 48px 95px;
  .background_logo {
    position: absolute;
    width: 478.78px;
    clip-path: inset(-6% 0% 28% 21%);
    top: 0;
    right: 50%;
     
      svg {
       
        height: 100%;
        clip-path: inset(-45% -2% -10% -18%);
        transform: rotate(84.54deg);
      }

      
    }
    .login_cont {
      display: flex;
      flex-direction: column;
      align-items: center;
      .logo {
        margin-bottom: 40px;
      }
      .login {
        width: 100%;

        display: flex;
        flex-direction: column;
        gap: 24px;
      }
    }
  }
  .rightcont {
    width: 50%;
    max-width: 867px;
    height: 100%;
 
   
    background-image: url("./background.jpg");
    background-size: cover;
    background-position: -658px -1px;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
